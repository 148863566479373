const words = [
  'Luxurious Living',
  'Modern Amenities',
  'Convenient Location',
  'Serenity and Comfort',
  'Elegant Residences',
  'Unparalleled Lifestyle',
  'Exceptional Community',
  'Your Gateway to Tranquility',
];
  export default words;