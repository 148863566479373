import React from 'react';
import { Footer , GalleryNav } from "../../Components"

export const AmenitiesPage = () => {
  return (
    <div className='main'>
      <GalleryNav />
      <section id="skills" class="skills section-bg ">
        <div class="container">

          <div class="section-title">
            <h2>Amenities</h2>
            <p>I possess a diverse range of skills in web development and design. I am proficient in HTML, CSS, and JavaScript, as well as React.js and Next.js frameworks. I have experience using Tailwind CSS for responsive design and Figma for UI/UX design. Additionally, I have expertise in using CMS platforms like WordPress and KeystoneJS.</p>
          </div>
          <div class="row">
            <div class="col-md-12 ab-sec amenitiesDiv">
              <div class="col-md-6 aboutTextImg">
                <h3 class="wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="300ms"><span>W</span>e Are Creative And Awesome</h3>
                <p><span><strong>L</strong></span>orem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.Lorem Ipsum is simply dummy text of the printing and typesetting industry. book. </p>
              </div>
              <div class="col-md-6 ab-sec-img wow fadeInUp amenitiesimgDiv" data-wow-duration="1000ms" data-wow-delay="300ms">
                <img src={require("../../assets/about/about-1.jpg")} alt="" />

              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 ab-sec row amenitiesDiv reversWrap">

              <div class="col-md-6 ab-sec-img wow fadeInUp amenitiesimgDiv Rivers" data-wow-duration="1000ms" data-wow-delay="300ms">
                <img src={require("../../assets/about/about-1.jpg")} alt="" />

              </div>
              <div class="col-md-6 aboutTextImg">
                <h3 class="wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="300ms"><span>W</span>e Are Creative And Awesome</h3>
                <p><span><strong>L</strong></span>orem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.Lorem Ipsum is simply dummy text of the printing and typesetting industry. book. </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 ab-sec row amenitiesDiv">
              <div class="col-md-6 aboutTextImg">
                <h3 class="wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="300ms"><span>W</span>e Are Creative And Awesome</h3>
                <p><span><strong>L</strong></span>orem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.Lorem Ipsum is simply dummy text of the printing and typesetting industry. book. </p>
              </div>
              <div class="col-md-6 ab-sec-img wow fadeInUp amenitiesimgDiv" data-wow-duration="1000ms" data-wow-delay="300ms">
                <img src={require("../../assets/about/about-1.jpg")} alt="" />

              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
};