import React, { useState } from "react"
import { useDispatch } from "react-redux";
import { signin } from "../../redux/actions";

export function LoginPage() {
    const dispatch = useDispatch()
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const onLoginPress = () => {
        if (!email) {
            alert('please enter email')
            return
        }
        if (!password) {
            alert("plaese enter password")
            return
        }
        dispatch(signin(email, password))
    }
    return (
        <div id="authentication" className="mainLoginDiv" >
            <div className="background">
                <div className="shape"></div>
                <div className="shape"></div>
            </div>
            <div className="LoginFormUi">
                <h3>Login Here</h3>

                <label>Username</label>
                <input onChange={(e) => setEmail(e.target.value)} value={email || ""} type="email" placeholder="Email or Username" id="username" />

                <label>Password</label>
                <input onChange={(e) => setPassword(e.target.value)} value={password || ""} type="password" placeholder="Password" id="password" />

                <button onClick={onLoginPress} className="loginBtn">Log In</button>
            </div>
        </div>
    )
}