import { types } from '../../actionTypes';
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth } from '../../../config';

export const signout = () => async (dispatch) => {
    signOut(auth).then(() => {
        // Sign-out successful.
    }).catch((error) => {
        // An error happened.
    });
};

export const signin = (email, password) => async (dispatch) => {
    signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
        })
        .catch((error) => {
            alert(error.message)
        });
}

export const setUserData = (user) => async (dispatch) => {
    dispatch({ type: types.AUTH_SUCCESS, user: user });
}