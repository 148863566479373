import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { uploadImageDataInGallery, deleteImageFromGallery, signout } from '../../redux/actions';

export function GalleryDashboard() {
  const dispatch = useDispatch();

  const { allImages } = useSelector(({ gallery }) => gallery);
  const user = useSelector(({ auth }) => auth.user);
  const [selectedData, setSelectedData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = (data) => {
    setSelectedData({ ...data })
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setSelectedData({})
    setIsModalOpen(false);
  };

  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    setSelectedData({ ...selectedData, IMAGE_URL: URL.createObjectURL(file), file: file });
  };

  const addNewImage = () => {
    if (!selectedData.file) {
      return
    }
    const fileExtension = selectedData.file.type.split("/")[1];
    const newID = Date.now().toString()
    const newImageName = `${newID}.${fileExtension}`;
    const newImageData = {
      IMAGE_URL: selectedData.IMAGE_URL,
      LABEL: selectedData.LABEL || "",
      CAPTION: selectedData.CAPTION || "",
      ID: newID,
      NAME: newImageName,
      alignment: allImages.length + 1,
      visible: true,
      file: selectedData.file,
      createdBy: user?.email || "not provided",
      updatedBy: user?.email || "not provided",
    };
    dispatch(uploadImageDataInGallery(newImageData, () => {
      closeModal();
    }))
  }
  const updateImage = () => {
    dispatch(uploadImageDataInGallery({ ...selectedData, updatedBy: (user?.email || "not provided") }, () => {
      closeModal();
    }))
  }
  const handleSubmit = () => {
    if (selectedData && selectedData.ID) {
      updateImage()
    } else {
      addNewImage()
    }
  };
  const onDeleteImage = (e) => {
    const shouldDelete = window.confirm("Are you sure you want to delete this image?");
    if (shouldDelete) {
      dispatch(deleteImageFromGallery(e))
    } else {
      return;
    }
  }
  const onSignOut = () => {
    const shouldDelete = window.confirm("Are you sure you want to signout?");
    if (shouldDelete) {
      dispatch(signout())
    } else {
      return;
    }
  }
  return (
    <div id="dashboard" className="main gallery-dashboard">
      <button onClick={onSignOut} className="signout_button">Sign Out</button>
      <button
        className="floating-button"
        onClick={() => openModal({})}
      >
        +
      </button>
      <section id="portfolio" className="portfolio section-bg dashboardGalleryImgRander">
        <div className="container dashboardGalleryUiMainDiv">

          <div className="section-title">
            <h2>Gallery images</h2>
          </div>

          <div className="row galleryImgRanderDiv">
            {!!allImages && !!allImages?.length && allImages.map((image) => (
              <div key={image.ID} className="col-lg-4 col-md-6 portfolio-item filter-web img2 hightControl">
                <div className="portfolio-wrap galleryImgDiv hightControlHomePage">
                  <img src={image?.IMAGE_URL} className="galleryImg" alt="galleryPicture" />
                  <div className="portfolio-info">
                    <h4>{image?.LABEL || ""}</h4>
                    <p>{image?.CAPTION || ""}</p>
                    <div className='editDeletIconMainDiv'>
                      <div className="portfolio-links surserChange" onClick={() => openModal(image)}>
                        <i className="bx bx-edit editIcon"></i>
                      </div>
                      <div className="portfolio-links surserChange deletIconDiv" onClick={() => onDeleteImage(image)}>
                        <i className='bx bx-trash deletIcon'></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>))}

          </div>
          {isModalOpen && (
            <div id="myModal" className="modal1" >
              <div className="modal-content">
                <div className="modal-head">
                  <span className="modalHeading">{selectedData?.ID ? "Edit" : "Add"} Details</span>
                  <span onClick={closeModal} id="close" className="close">&times;</span>
                </div>
                <div className="modalFields">
                  <div className="uploadImgInputDiv">
                    <input className="inputUpload" type="file" id="myFile" name="filename" accept='image/*' onChange={handleImageSelect} />
                  </div>
                  <div>
                    {(selectedData?.IMAGE_URL) && <img height={100} width={100} src={selectedData?.IMAGE_URL} alt="Selected" />}
                  </div>
                  <div>
                    <input className="inputText" type="text" placeholder="Label" value={selectedData?.LABEL || ""} onChange={(e) => setSelectedData({ ...selectedData, LABEL: e.target.value })} />
                  </div>
                  <div>
                    <input
                      className="inputText"
                      type="text"
                      placeholder="Caption"
                      value={selectedData?.CAPTION || ""}
                      onChange={(e) => setSelectedData({ ...selectedData, CAPTION: e.target.value })}
                    />
                  </div>
                  <div className="modalCloseSaveBtnDiv">
                    <button id="modalClose" onClick={closeModal}>Close</button>
                    <button onClick={handleSubmit}>{selectedData?.ID ? "Update" : "Submit"}</button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  )
}