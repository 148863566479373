import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export function Gallery() {
    const navigate = useNavigate();

    const { isLoading, images } = useSelector(({ gallery }) => gallery);

    const renderImages = (data) => {
        if (data.length > 6) {
            data.length = 6
        }
        return data.map((e, i) => {
            return (
                <div key={i} className="col-lg-4 col-md-6 portfolio-item filter-web img2 hightControl">
                    <div className="portfolio-wrap galleryImgDiv hightControlHomePage">
                        <img src={e?.IMAGE_URL} className="galleryImg" alt={e?.LABEL || e?.NAME} />
                        <div className="portfolio-info">
                            <h4>{e?.LABEL}</h4>
                            <p>{e?.CAPTION}</p>
                            <div className="portfolio-links">
                                <a href={e?.IMAGE_URL} data-gallery="portfolioGallery" className="portfolio-lightbox" title="View Full Screen"><i className="bx bx-plus"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    const loaderFunc = () => {
        return (
            <div />
        )
    }
    return (
        <section id="portfolio" className="portfolio section-bg portfolioflex">
            {isLoading && loaderFunc()}
            <div className="container" data-aos="fade-left">

                <div className="section-title">
                    <h2>Gallery</h2>
                    <p>Take a visual journey through the captivating ambiance and exquisite details of Moon Garden Apartments. Browse our gallery to get a glimpse of our modern living spaces and vibrant community.</p>
                </div>

                <div className="row portfolio-container galleryImgRanderDiv" data-aos="fade-up" data-aos-delay="200">

                    {images?.length && renderImages([...images])}

                </div>
                <div className="mainDivSeeMoreCount">
                   <button onClick={() => navigate("/gallery")} className="galleryPageBtn">See More</button>
                </div>
            </div>
        </section>
    )
}