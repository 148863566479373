import { BsEnvelope } from "react-icons/bs";
import { CiMobile2 } from "react-icons/ci";

export function Footer() {

    const handleEmailClick = () => {
        window.open("mailto:moonbuilders.projects@gmail.com", "_blank");
    };

    const handlePhoneClick = () => {
        const phoneNumber = "+923272018338";
        window.location.href = `tel:${phoneNumber}`;
    };

    return (
        <footer id="footer" className="footerMain">
            <div class="container" data-aos="fade-right">
                <h3>MOON GARDEN</h3>
                {/* <p>Discover the Perfect Place to Call Home at Moon Garden.</p> */}
                <p>Experience exceptional living at Moon Garden Apartments - Your ideal destination for comfort and convenience.</p>
                <div class="social-links">
                    {/* <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a> */}
                    <a href="https://www.facebook.com/Moongarden.pk" target="_blank" class="facebook"><i class="bx bxl-facebook"></i></a>
                    <a href="https://www.instagram.com/moongarden.pk/" target="_blank" class="instagram"><i class="bx bxl-instagram"></i></a>
                    {/* <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
                        <a href="https://www.linkedin.com/in/nisar-zaidi-331a0b272/" target="_blank" class="linkedin"><i class="bx bxl-linkedin"></i></a> */}
                </div>
                <div class="emailAndPhoneDiv">
                    <div className="emailDiv" onClick={handleEmailClick}>
                        <i class="bi bi-envelope"><BsEnvelope className="contactIcon" /></i>
                        <span>moonbuilders.projects@gmail.com</span>
                    </div>
                    <div className="phoneDiv" onClick={handlePhoneClick}>
                        <i class="bi bi-phone"><CiMobile2 className="contactIcon" /></i>
                        <p>+92327-2018338</p>
                    </div>
                </div>
                <div class="copyright">
                    &copy; 2023 <strong><span>Moon Garden</span></strong> Apartments. All rights reserved.
                </div>
            </div>
        </footer>
    )
}