import React, { useEffect } from 'react'
import Typing from "../typingWordsFuntion/typingWord";
import 'boxicons/css/boxicons.min.css';
import Aos from "aos";
import "aos/dist/aos.css"
import { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
// import 'boxicons'


export function NavigationBar() {
    const { buttons } = useSelector(({ general }) => general)
    console.log(buttons)
    useEffect(() => {
        Aos.init({ duration: 1400 });
    }, []);

    const navMenuControl = () => {
        setTimeout(() => {

            let btn = document.getElementById("nav_menu_btn");
            let modal = document.getElementById("mobil_nev_active");
            console.log(btn);
            if (btn.className === "bx bx-menu mobile-nav-toggle") {
                btn.className = "bx bx-x mobile-nav-toggle"
                modal.className = "modalActive"
                // modal.style.display = "block !important"
            } else {
                btn.className = "bx bx-menu mobile-nav-toggle";
                modal.className = "modal";
            }

            let links = modal.getElementsByTagName("a");
            for (let i = 0; i < links.length; i++) {
                links[i].addEventListener("click", () => {
                    modal.className = "modal";
                    btn.className = "bx bx-menu mobile-nav-toggle";
                });
            }

        }, 500);
    }



    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };



    return (
        <div className="mainDiv">
            <div>
                <div className='res-menu'>
                    <i onClick={() => navMenuControl()} id='nav_menu_btn' class="bx bx-menu mobile-nav-toggle"></i>
                </div>
                <header id="header" class="centerDiv">
                    <nav id="navbar" class="navbar nav-menu">
                        <ul>
                            <li><a href="#hero" class="nav-link scrollto active"><i class="bx bx-home"></i> <span>Home</span></a></li>
                            <li><a href="#about" class="nav-link scrollto"><i class="bx bx-user"></i> <span>About</span></a></li>
                            <li><a href="#skills" class="nav-link scrollto"><i class="bx bx-file-blank"></i> <span>Amenities</span></a></li>
                            <li><a href="#portfolio" class="nav-link scrollto"><i class="bx bx-book-content"></i> <span>Gallery</span></a></li>
                            <li><a href="#contact" class="nav-link scrollto"><i class="bx bx-envelope"></i> <span>Contact</span></a></li>
                            {/* <li><a href="#admin" class="nav-link scrollto"><i class="bx bx-server"></i> <span>Services</span></a></li> */}
                        </ul>
                    </nav>

                </header>
                <section id="hero" class="centerDiv">
                    <div class="container " data-aos="zoom-in" data-aos-delay="100">
                        <h1>MOON GARDEN APARTMENTS</h1>
                        <p>Provident <span><Typing /></span></p>
                        <div className='sliderVideoMainDiv'>
                            <div className='sliderVideoMainDivChild'>
                                <div className="slider-video-btn" onClick={openModal}>
                                    <div className="btn-left-img">
                                        <img className="slider-btn-img" src={require("../../assets/images/videoIcon.png")} alt="icon" />
                                    </div>
                                    <div className="btn-right-text">
                                        <span className="slider-video-btn-text">Watch Intro</span>
                                    </div>
                                </div>
                                {!!buttons?.mainBanner2ndBtn?.visible && <div className="slider-book-now-btn" onClick={() => !!buttons?.mainBanner2ndBtn?.URL ? window.open(buttons?.mainBanner2ndBtn?.URL, '_blank') : null}>
                                        <span className="slider-video-btn-text">{buttons?.mainBanner2ndBtn?.text || ""}</span>
                                </div>}
                            </div>
                            {/* Modal */}
                            <Transition.Root show={isOpen} as={Fragment} className={"dailoBoxVideo"}>
                                <Dialog
                                    as="div"
                                    className="fixed inset-0 z-10 overflow-y-auto"
                                    onClose={closeModal}
                                >
                                    <div className="fixed inset-0 flex items-center justify-center z-50">
                                        <Transition.Child
                                            as={Fragment}
                                            enter="ease-out duration-300"
                                            enterFrom="opacity-0"
                                            enterTo="opacity-100"
                                            leave="ease-in duration-200"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
                                        </Transition.Child>

                                        <Transition.Child
                                            as={Fragment}
                                            enter="ease-out duration-300"
                                            enterFrom="opacity-0 scale-95"
                                            enterTo="opacity-100 scale-100"
                                            leave="ease-in duration-200"
                                            leaveFrom="opacity-100 scale-100"
                                            leaveTo="opacity-0 scale-95"
                                        >
                                            <div className="fixed inset-0 flex items-center justify-center">
                                                <div className="mainDivVideo">
                                                    {/* Add your video player component here */}
                                                    {/* For example, you can use an HTML5 video tag */}
                                                    <video controls autoPlay className="w-full h-full videoDiv">
                                                        <source src={require("../../assets/images/MoonGraden.mp4")} type="video/mp4" />
                                                    </video>

                                                    {/* Add a focusable button */}
                                                    <img onClick={closeModal} className='closeBtn' src={require("../../assets/images/dailogBox-closeImg.png")}></img>
                                                </div>
                                                {/* <button onClick={closeModal} className='closeBtn'>X</button> */}
                                            </div>
                                        </Transition.Child>
                                    </div>
                                </Dialog>
                            </Transition.Root>
                        </div>
                        <div class="social-links">
                            {/* <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a> */}
                            <a href="https://www.facebook.com/Moongarden.pk" target="_blank" class="facebook"><i class="bx bxl-facebook"></i></a>
                            <a href="https://www.instagram.com/moongarden.pk/" target="_blank" class="instagram"><i class="bx bxl-instagram"></i></a>
                            {/* <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
                            <a href="https://www.linkedin.com/in/nisar-zaidi-331a0b272/" target="_blank" class="linkedin"><i class="bx bxl-linkedin"></i></a> */}
                        </div>
                    </div>
                </section>
                <div id='mobil_nev_active' className='modal'>
                    <div className='mobil-res-navbar-modal'>
                        <div className='mobil-nev'>
                            <ul>
                                <li><a href="#hero" class="nav-link scrollto active"><i class="bx bx-home"></i> <span>Home</span></a></li>
                                <li><a href="#about" class="nav-link scrollto"><i class="bx bx-user"></i> <span>About</span></a></li>
                                <li><a href="#skills" class="nav-link scrollto"><i class="bx bx-file-blank"></i> <span>Amenities</span></a></li>
                                <li><a href="#portfolio" class="nav-link scrollto"><i class="bx bx-book-content"></i> <span>Gallery</span></a></li>
                                <li><a href="#contact" class="nav-link scrollto"><i class="bx bx-envelope"></i> <span>Contact</span></a></li>
                                {/* <li><a href="#admin" class="nav-link scrollto"><i class="bx bx-server"></i> <span>Services</span></a></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}