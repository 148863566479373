import { types } from '../../actionTypes';

let initialState = {
    isLoading: false,
    generalData: {},
    buttons: {},
};

export function generalDataReducer(state = initialState, action) {
    switch (action.type) {

        case types.GET_GENERAL_INFO_START:
            return { ...state, isLoading: true };
        case types.GET_GENERAL_INFO_SUCCESS:
            let tempButtons = { ...state.buttons }
            if (action.generalData && action.generalData.buttons) {
                tempButtons = action.generalData.buttons
            }
            return { ...state, generalData: action.generalData || {}, buttons: tempButtons || {}, isLoading: false };
        case types.GET_GENERAL_INFO_FAILED:
            return { ...state, isLoading: false, generalData: {} };

        default:
            return state;
    }
}