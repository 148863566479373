import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

export function Amenities() {
  const navigate = useNavigate();

  return (
    <section id="skills" class="skills section-bg ">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Amenities</h2>
          <p>Indulge in an array of top-notch amenities at Moon Garden Apartments, including a well-equipped gym, play area, secure parking, CCTV surveillance, and convenient elevators for a comfortable living experience.</p>
        </div>
        <div class="row amenitiesTextImgMainDiv">
          <div class="col-md-12 ab-sec amenitiesDiv">
            <div data-aos="fade-left" class="col-md-6 aboutTextImg">
              <h3 class="wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="300ms">Play Area: Where Imagination Takes Flight</h3>
              <p>Unleash your child's imagination in our vibrant and safe play area. Designed to inspire creativity and endless fun, our play area offers a variety of play equipment and interactive spaces. From swings and slides to climbing structures, every corner is thoughtfully crafted to ensure your child's enjoyment and safety. At Moon Garden Apartments, we believe in providing a stimulating environment that encourages social interaction and active play for our young residents.</p>
            </div>
            <div data-aos="flip-left" class="col-md-6 ab-sec-img wow fadeInUp amenitiesimgDiv" data-wow-duration="1000ms" data-wow-delay="300ms">
              <img src={require("../../../assets/images/playArea.jpeg")} alt="" />

            </div>
          </div>
        </div>
        <div class="row amenitiesTextImgMainDiv">
          <div class="col-md-12 ab-sec row amenitiesDiv reversWrap">

            <div data-aos="flip-right" class="col-md-6 ab-sec-img wow fadeInUp amenitiesimgDiv Rivers" data-wow-duration="1000ms" data-wow-delay="300ms">
              <img src={require("../../../assets/images/gym.jpeg")} alt="" />

            </div>
            <div data-aos="fade-right" class="col-md-6 aboutTextImg">
              <h3 class="wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="300ms">Fitness Center: Elevate Your Workout Routine</h3>
              <p>Welcome to our state-of-the-art fitness center at Moon Garden Apartments. With a wide range of modern equipment and a spacious layout, our gym is designed to accommodate all fitness levels. Whether you prefer cardio exercises, strength training, or group classes, our fitness center has you covered. Our experienced trainers are also available to provide guidance and support to help you achieve your fitness goals. Elevate your workout routine and embrace a healthy lifestyle at Moon Garden Apartments.</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 ab-sec row amenitiesDiv">
            <div data-aos="fade-left" class="col-md-6 aboutTextImg">
              <h3 class="wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="300ms">Convenient Parking: Peace of Mind for Your Vehicle</h3>
              <p>At Moon Garden Apartments, we understand the importance of secure parking. Our well-maintained parking facility offers ample space for residents and their guests. With designated parking spots and vigilant security measures, you can have peace of mind knowing that your vehicle is safe and protected. Whether you have one car or multiple vehicles, our parking area provides convenience and accessibility. Enjoy the reassurance of a dedicated parking space when you choose to call Moon Garden Apartments your home.</p>
            </div>
            <div data-aos="flip-left" class="col-md-6 ab-sec-img wow fadeInUp amenitiesimgDiv" data-wow-duration="1000ms" data-wow-delay="300ms">
              <img className="amenitiesImages" src={require("../../../assets/images/parking.jpeg")} alt="" />
            </div>
          </div>
        </div>
        <div className="mainDivSeeMoreCount">
          <button onClick={() => navigate("/amenities")} className="amenitiesPageBtn">See More</button>
        </div>
      </div>
    </section>
  )
}