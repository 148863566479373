import React, { useEffect } from 'react'
import Typing from "../typingWordsFuntion/typingWord";
import 'boxicons/css/boxicons.min.css';
import Aos from "aos";
import "aos/dist/aos.css"
import { useNavigate } from 'react-router-dom';
// import 'boxicons'


export function GalleryNav() {
    const navigate = useNavigate();
    useEffect(() => {
        Aos.init({ duration: 1400 });
    }, []);

    // const navMenuControl = () => {
    //     setTimeout(() => {

    //         let btn = document.getElementById("nav_menu_btn");
    //         let modal = document.getElementById("mobil_nev_active");
    //         console.log(btn);
    //         if (btn.className === "bx bx-menu mobile-nav-toggle") {
    //             btn.className = "bx bx-x mobile-nav-toggle"
    //             modal.className = "modalActive"
    //             // modal.style.display = "block !important"
    //         } else {
    //             btn.className = "bx bx-menu mobile-nav-toggle";
    //             modal.className = "modal";
    //         }

    //         let links = modal.getElementsByTagName("a");
    //         for (let i = 0; i < links.length; i++) {
    //             links[i].addEventListener("click", () => {
    //                 modal.className = "modal";
    //                 btn.className = "bx bx-menu mobile-nav-toggle";
    //             });
    //         }

    //     }, 500);
    // }
    return (
        <div className="mainDiv">
            <div>
                {/* <div className='res-menu'>
                    <i onClick={() => navMenuControl()} id='nav_menu_btn' class="bx bx-menu mobile-nav-toggle"></i>
                </div> */}
                <header id="header" class="d-flex flex-column justify-content-center">
                    <nav id="navbar" class="navbar nav-menu nav-manu-gallery">
                        <ul>
                            <li><a onClick={() => navigate("/")} class="nav-link scrollto active"><i class="bx bx-home"></i> <span>Home</span></a></li>
                        </ul>
                    </nav>
                </header>
                        <div className='homeBTn'>
                            <ul>
                                <li><a onClick={() => navigate("/")} class="nav-link scrollto active"><i class="bx bx-home"></i></a></li>
                            </ul>
                        </div>
            </div>
        </div>
    )
}