import { types } from '../../actionTypes';
import { db, storage } from '../../../config';
import { collection, query, where, onSnapshot, doc, setDoc, deleteDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";


let getGeneralInfoFlag = true
export const getGeneralInfo = () => async (dispatch) => {
    if (getGeneralInfoFlag) {
        dispatch({ type: types.GET_GENERAL_INFO_START });
        getGeneralInfoFlag = false;
        try {
            const q = collection(db, "general");
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const updatedData = {};
                querySnapshot.forEach((doc) => {
                    const item = doc.data();
                    updatedData[doc.id] = item;
                });
                dispatch({ type: types.GET_GENERAL_INFO_SUCCESS, generalData: updatedData });
            });
        } catch (error) {
            dispatch({ type: types.GET_GENERAL_INFO_FAILED });
        }
    }
};