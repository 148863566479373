import React from 'react';
import { NavigationBar, Footer } from '../../Components';
import { About } from './about';
import { Amenities } from './amenities';
import { Gallery } from './gallery';
import { Contact } from './contact';
import WhatsAppButton from '../../Components/whatsappFlotingBtn';

export const Main = () => {
  return <div className="main">
    <NavigationBar />
    <About />
    <Amenities />
    <Gallery />
    <Contact />
    <Footer />
    <WhatsAppButton />
  </div>;
};