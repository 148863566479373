import AOS from "aos"

export function About() {

  AOS.init({
    duration: 2000,
  })
  
  return (
    <div className="main">
      <section id="about" className="about">
        <div class="container" data-aos="fade-right">
          <div class="col-md-12 col-md-offset-2">
            <div class="heading About">
              <div class="section-title">
                <h2>About Us</h2>
              </div>
              <div class="line"></div>
              <p>Immerse yourself in a vibrant community and enjoy modern amenities at Moon Garden Apartments, where comfort, convenience, and quality living come together.</p>
            </div>
          </div>
          <div class="row mainDivSectionAbout">
            <div class="col-md-12 ab-sec divRow">
              <div data-aos="fade-left" class="col-md-6 aboutTextImg">
                <h3 class="wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="300ms">Welcome to Moon Garden Apartments!</h3>
                <p>where modern living meets convenience! Situated in a prime corner location, our apartments offer an ideal setting for a fulfilling lifestyle. Enjoy a range of amenities including a well-equipped gym, dedicated play area, secure parking, CCTV surveillance, and convenient elevators. At Moon Garden, we foster a vibrant community where neighbors become friends and lasting memories are created. Experience the best of apartment living in a location that combines comfort, convenience, and a welcoming atmosphere.</p>
              </div>
              {/* <div class="col-md-6 ab-sec-img wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="300ms"> */}
              {/* <img src="images/Aboutus/01.jpg" alt="" /> */}
              <div class="aboutImgDiv">
                <div class="aboutImgDivChild">
                  <div class="col-6 text-start borders">
                    <img data-aos="flip-right" class="imgbig" data-wow-delay="0.1s" src={require("../../../assets/images/aboutImg.jpeg")} />
                  </div>
                  <div  class="col-6 text-start bord">
                    <img data-aos="flip-left" class="imgsmall" data-wow-delay="0.3s" src={require("../../../assets/images/buildingLift.jpeg")} />
                  </div>
                </div>
                <div class="aboutImgDivChild">
                  <div class="col-6 text-start bord2">
                    <img data-aos="flip-right" class="imgsmall" data-wow-delay="0.1s" src={require("../../../assets/images/buildingImg1.jpg")} />
                  </div>
                  <div class="col-6 text-start borders1">
                    <img data-aos="flip-left" class="imgbig" data-wow-delay="0.3s" src={require("../../../assets/images/mapImg.jpg")} />
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}