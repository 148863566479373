import { IoLocationOutline } from "react-icons/io5";
import { BsEnvelope } from "react-icons/bs";
import { CiMobile2 } from "react-icons/ci";


export function Contact() {
    return (
        <section id="contact" class="contact contactWith">
            <div class="container" data-aos="fade-right">

                <div class="section-title">
                    <h2>Contact</h2>
                </div>
                
                <div class="row mt-1 contactRow">

                    <div class="col-lg-4 contactRow1">
                        {/* <div class="info">
                            <div class="address">
                                <i class="bi bi-geo-alt"><IoLocationOutline className="contactIcon"/></i>
                                <h4>Location:</h4>
                                <p>Karachi Pakistan</p>
                            </div>

                            <div class="email">
                                <i class="bi bi-envelope"><BsEnvelope className="contactIcon"/></i>
                                <h4>Email:</h4>
                                <p>Nisarzaidi75@gmail.com</p>
                            </div>

                            <div class="phone">
                                <i class="bi bi-phone"><CiMobile2 className="contactIcon"/></i>
                                <h4>Call:</h4>
                                <p>+92301-2122387</p>
                            </div>

                        </div> */}

                    </div>

                    <div class="col-lg-8 mt-5 mt-lg-0 contactRow2">

                        <form action="https://formspree.io/f/xvonjrvo" method="post" role="form" class="php-email-form">
                            <div class="row inputMainDiv">
                                <div class="col-md-6 form-group">
                                    <input type="text" name="name" class="form-control input" id="name" placeholder="Your Name" required />
                                </div>
                                <div class="col-md-6 form-group">
                                    <input type="email" class="form-control input" name="email" id="email" placeholder="Your Email" required />
                                </div>
                            </div>
                            <div class="form-group mt-3">
                            <input type="tel" id="phone" class="form-control input" name="phone" placeholder="Phone / WhatsApp Number" required />
                                {/* <input type="text" class="form-control input" name="subject" id="subject" placeholder="Subject" required /> */}
                            </div>
                            <div class="form-group mt-3">
                                <textarea class="form-control input" name="message" rows="5" placeholder="Message" required></textarea>
                            </div>
                            <div class="my-3">
                                <div class="loading">Loading</div>
                                <div class="error-message"></div>
                                <div class="sent-message">Your message has been sent. Thank you!</div>
                            </div>
                            <div class="text-center"><button type="submit">Send Message</button></div>
                        </form>

                    </div>

                </div>

            </div>
        </section>
    )
}