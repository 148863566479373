import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import * as WEB_SCREENS from '../Screens';
import * as ADMIN_SCREENS from '../AdminDashboard';
import * as AUTH_SCREENS from '../Authentication';
import { getGalleryImages, getGalleryImagesForAdmin, getGeneralInfo, setUserData } from '../redux/actions';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../config';


const RoutingFile = () => {
    const user = useSelector(({ auth }) => auth.user);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getGeneralInfo());
    }, [])
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                dispatch(setUserData(currentUser))
            } else {
                dispatch(setUserData(null))
            }
        });

        return () => {
            unsubscribe();
        };
    }, [dispatch]);
    useEffect(() => {
        dispatch(getGalleryImages())
        if (user) {
            dispatch(getGalleryImagesForAdmin())
        }
    }, [user, dispatch])

    const PrivateRoute = ({ element: Element, ...rest }) => {
        return user ? (
            <Element {...rest} />
        ) : (
            <Navigate to="/login" replace state={{ from: rest.location }} />
        );
    };
    const LoginRedirection = ({ location }) => {
        const from = location?.state?.from || '/admin';
        return user ? <Navigate to={from} replace /> : <AUTH_SCREENS.LoginPage />;
    };

    return (
        <Router>
            <Routes>
                <Route path="/" element={<WEB_SCREENS.Main />} />
                <Route path="/amenities" element={<WEB_SCREENS.AmenitiesPage />} />
                <Route path="/gallery" element={<WEB_SCREENS.GalleryPage />} />
                <Route path="/login" element={<LoginRedirection />} />
                <Route path="/admin" element={<PrivateRoute element={ADMIN_SCREENS.AdminDashboard} />} />
                <Route path="/gallery-admin" element={<PrivateRoute element={ADMIN_SCREENS.GalleryDashboard} />} />
            </Routes>
        </Router>
    );
};

export default RoutingFile;