import React from 'react';
import './style.css';
import { useNavigate } from 'react-router-dom';



export const AdminDashboard = () => {
    const navigate = useNavigate();

    return(
        <div className="dashboardMainDiv">
            <button className='galleryDashboardBtn' onClick={() => navigate("/gallery-admin")}>Gallery</button>;
        </div>
    )

};