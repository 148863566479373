import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const WhatsAppButton = () => {
    const handleClick = () => {
        // window.location.href = 'https://api.whatsapp.com/send?phone=+923272018338'; // Replace with your WhatsApp number
        // window.open('https://api.whatsapp.com/send?phone=+923272018338', '_blank');
        window.open('//api.whatsapp.com/send?phone=923272018338', '_blank');
    };
    return (
        <div
            style={{
                position: 'fixed',
                bottom: '20px',
                right: '20px',
                width: '60px',
                height: '60px',
                borderRadius: '50%',
                background: '#25d366',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
            }}
            onClick={handleClick}
        >
            <FaWhatsapp size={30} color="#ffffff" />
        </div>
    );
};

export default WhatsAppButton;